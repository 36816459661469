import request from '@/service/lib/request';



export function getList (data) {
  return request({
    url: '/article/cases',
    method: 'get',
    params: data
  });
}
export function getModel (id) {
  return request({
    url: '/article/case/' + id,
    method: 'get'
  });
}
export function saveModel (data) {
  return request({
    url: '/article/case',
    method: 'post',
    data
  });
}
export function deleteModel (id) {
  return request({
    url: '/article/case/' + id,
    method: 'delete'
  });
}